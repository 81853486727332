.wrapper-fs {
  position: relative;
  cursor:pointer;
}

.filter-switch{
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 40px;
  z-index: 1;
  margin: auto;
  justify-content: center;
  background-color: var(--background-primary);
  box-shadow: 2px 2px 2px 0px var(--shadow);
}

.filter-switch:hover{
  background-color: #dddddd;
}

.filter-switch:active{
  background-color: var(--shadow);
}

.filter-switch img {
  height: 24px;
  aspect-ratio: 1/1;
  align-self: center;
  filter: var(--filter)
}

@media (min-width: 768px) {
  .filter-switch{
    display: none;
  }
  }