@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');

.logo-img{
 background-image: var(--logo);
 background-size: cover;
 width: 100px;
 height: 100px;
}

.logo{
  display:none
}


  @media (min-width: 768px) {
    .logo-img{
      display: none;
    }

    .logo {
      display: block;
      text-align: center;
      align-content: flex-start;
      justify-content: center;
      font-size: 0.7em;
      margin-top: 10px;
      }
      
      h1 {
        font-family: 'Amaranth', sans-serif;
      }
      
      .headlinePurple {
        color: #7C2AE8;
      }
      
      .headlineBlue {
        color: #00C4CC;
      }
  }