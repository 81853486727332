.search-wrapper {
  display: flex;
  padding: 0;
  justify-content: center;
  align-content: center;
  position: relative;
  max-width: 400px;
  max-height: 35px;
  margin: auto;
  flex: 1;
}

input {
  border: 1.5px solid rgb(195, 191, 191);
  border-radius: 20px;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
  opacity: 0.6;
  width: 90%;
  filter: var(--filter);
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--font-weight);
}

input:focus {
  box-shadow: 0 0 3px 0 var(--accent);
  border-color: var(--accent);
  outline: none;
  filter: invert(0);
  color: var(--font-color);
}

.close-button {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 6px;
  bottom: 0;
  right: 20px;
  border: 1px solid transparent;
  border-radius: inherit;
  background: transparent url('../../img/close.svg') no-repeat center;
  cursor: pointer;
  opacity: 0.5;
  filter: var(--filter);
}

.close-button.close-button-hidden {
  display: none;
}

@media (min-width: 768px) {
  .search-wrapper {
    max-width: none;
    width: 100%;
    height: 40px;
    margin: unset;
    margin-bottom: 15px;
  }

  input {
    width: 70%;
  }

  .close-button {
    right: 75px;
    top: 7px;
  }
}
