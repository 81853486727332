.filter-list{
  display: none;
}

label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--font-weight);
}

label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: var(--accent) 2px solid;
}

label:hover input ~ .checkmark {
  background-color: #ccc;
}

label input:checked ~ .checkmark {
  background-color: var(--accent);
}

ul{
  margin: auto;
  margin-bottom: 20px;
  padding-inline-start: 0;
}

.group-name {
  margin-bottom: 7px;
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--font-weight);
}

.group-name:not(:first-child) {
  margin-top: 20px;
}

.mobile-filters .filter-list{
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 10px;
}

.filter-btns-wrapper{
  display: flex;
  flex-direction: column;
 }
 

.btn-filters {
  margin-top: 30px;
  border-radius: 5px;
  background-color: #E0C9FF;
  color: #7C2AE8;
  font-family: "Amaranth", sans-serif;
  border: none;
  padding:8px 32px;
  cursor:pointer;
  box-shadow: 1.5px 1.5px 1.5px 1px var(--shadow);
}

.btn-filters.btn-secondary{
  margin-top: 20px;
  border-radius: 5px;
  background-color: var(--background-primary);
  color: #7C2AE8;
  font-family: "Amaranth", sans-serif;
  border: none;
  padding:8px 32px;
  cursor:pointer;
  box-shadow: none;
}

.btn-filters.btn-disabled{
  background-color: rgb(223, 223, 223);
  color: #C599FF;
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: 1.5px 1.5px 1.5px 1px var(--shadow);
}

.btn-filters.btn-secondary:hover {
  background-color: #e2e2e2;
}

.btn-filters:hover {
  background-color: #C599FF;
}

.btn-filters:active {
  position:relative;
	top:1px;
}
.filter-error{
  display: none;
}


@media (min-width: 768px) {

.filter-list{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .filter-btns-wrapper {
    display:none;
  }

  .filter-error{
    display: flex;
    margin-top: 20px;
    color: #00C4CC;
  }
}