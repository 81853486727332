.map {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.mapboxgl-popup-content {
  border-radius: 30px;
}

.marker-button {
  background-image: var(--marker);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  display: flex;
  outline: none;
  cursor: pointer;
  filter: var(--filter) var(--contrast);
}

.popup {
  display: flex;
  background-color: var(--background-secondary);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 150px;
  filter: var(--filter) var(--contrast);
  font-family: 'Open Sans', sans-serif;
}

.address {
  font-size: 0.8rem;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: bold;
}

.place-name {
  font-family: 'Amaranth', sans-serif;
  font-size: 1.4rem;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.category-name {
  color: white;
  font-weight: bold;
  background-color: #7c2ae8;
  border-radius: 10px;
  width: fit-content;
  height: auto;
  padding: 3px 13px 3px 13px;
  font-size: smaller;
  margin: auto;
  margin-bottom: 10px;
}

.equipment {
  font-size: 0.7rem;
  margin-bottom: 3px;
  font-weight: bold;
}

.popup .babysitting {
  background-image: url('../../img/placeholders/babysitting.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: 5 / 2;
  margin-bottom: 5px;
  filter: var(--image-filter);
}

.popup .cafe {
  background-image: url('../../img/placeholders/cafe.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: 5 / 2;
  margin-bottom: 5px;
  filter: var(--image-filter);
}

.popup .restaurant {
  background-image: url('../../img/placeholders/restaurant.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: 5 / 2;
  margin-bottom: 5px;
  filter: var(--image-filter);
}

.popup .outdoors {
  background-image: url('../../img/placeholders/outdoors.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: 5 / 2;
  margin-bottom: 5px;
  filter: var(--image-filter);
}

.popup .other {
  background-image: url('../../img/placeholders/other.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: 5 / 2;
  margin-bottom: 5px;
  filter: var(--image-filter);
}

.popup .playroom {
  background-image: url('../../img/placeholders/playroom.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  aspect-ratio: 5 / 2;
  margin-bottom: 5px;
  filter: var(--image-filter);
}

.social-icons {
  flex-direction: row;
  margin-top: 10px;
}

.social-link {
  filter: var(--filter);
  margin: 5px;
}

.mapboxgl-popup-close-button {
  display: none;
}

.instagram {
  position: absolute;
  bottom: 40px;
  left: 20px;
  font-size: small;
  text-align: center;
  background-color: white;
  opacity: 0.7;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

a:link {
  text-decoration: none;
  color: black;
}

a:visited {
  color: black;
}

.insta-icon {
  margin: 10px;
}

.insta-close {
  width: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}

@media (min-width: 768px) {
  .map {
    flex: 1;
    height: 100vh;
    overflow: hidden;
  }

  .popup {
    min-width: 350px;
  }
}
