.list {
  flex-direction: column;
  margin: auto;
  width: 80vw;
  padding-bottom: 20px;
}

@media (min-width: 768px) {
  .list {
    width: 350px;
  }
}
