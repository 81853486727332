@import url('https://fonts.googleapis.com/css2?family=Amaranth&display=swap');

* {
  box-sizing: border-box;
}

.App {
  background-color: var(--background-primary);
  height: 100%;
  color: var(--font-color);
  overflow-y: overlay;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.map {
  display: none;
}

.list-view-mobile-hidden .map {
  display: flex;
  overflow: hidden;
}

.list-view-mobile-hidden .list {
  display: none;
}

.sidebar {
  display: flex;
  flex-direction: column;
}

.mobile-filters .filter-list {
  display: flex;
}

.mobile-filters .list,
.mobile-filters .map,
.mobile-filters .view-switch {
  display: none;
}

@media (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: row;
  }

  .container .map {
    display: flex;
  }

  .container .list {
    display: flex;
  }

  .sidebar {
    overflow-y: scroll;
    width: 450px;
  }
}
