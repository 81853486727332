.list-view-mobile .theme-switch-wrapper {
  display: none;
}

.theme-switch-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 120px;
  left: 15px;
  z-index: 1;
}

.theme-switch-wrapper-hidden {
  display: none;
}

em {
  margin-left: 10px;
  font-size: 1rem;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.slider {
  background-color: #b9aec7;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #00c4cc;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (min-width: 768px) {
  .theme-switch-wrapper {
    top: 15px;
    left: auto;
    right: 15px;
  }
}
