:root {
  --background-primary:#ffffff;
  --gradient-colors: #FFFFFF, #E5E5E5;
  --font-color: #121212;
  --background-secondary: #FFFFFF;
  --marker: #7C2AE8;
  --accent: #00C4CC;
  --logo: url("./img/logo-square.png");
  --shadow: #d7d7d7;
  --filter: invert(0);
  --image-filter: brightness(1);
  --marker: url("./img/pin-purple.svg");
  --font-weight: normal;
  --contrast: unset;
  --grayscale: unset;
}

[data-theme='dark'] {
  --background-primary:#110522  ;
  --gradient-colors: black, #2B0163;
  --font-color: rgb(235, 233, 233);
  --background-secondary: #2b1a41;
  --marker: #FFFFFF;
  --accent: #00C4CC;
  --logo: url("./img/logo-transparent-bg.png");
  --shadow: none;
  --filter: invert(1);
  --image-filter: brightness(0.7);
  --marker: url("./img/pin-blue.svg");
  --font-weight: bold;
  --contrast: contrast(150%);
  --grayscale: grayscale(0.5);
}


#root {
  height: 100%;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
